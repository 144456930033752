<template>
	<div class="order_wrap">
		<MStepper />
		<v-container class="">
			<div class="txt_box px-6">
				<h1 class="tit_page">수납 (신용카드)</h1>
				<p class="txt_body1 mb-8">
					고객님이 납부하실 신용카드 정보를 등록해 주세요.
					<br />수납 금액은 총
					<span class="primary--text"> {{ recpInfos.amount | comma }} 원</span
					>입니다.
				</p>
			</div>
			<CardPayForm ref="card" :hidePaymentDate="true" />
			<div class="bottom_box px-6">
				<v-btn color="primary" class="col_1_small" @click="goRecp()">
					{{ recpInfos.amount | comma }} 원 수납 진행
				</v-btn>
				<v-btn
					depressed
					height="20"
					color=""
					class="btn_underline my-6"
					@click="onResetPayMethod"
				>
					수납방법 재선택
				</v-btn>
			</div>
			<CustomRecpDialog ref="recpPop" />
		</v-container>
	</div>
</template>
<script>
import filters from '@/mixins/filters'
import { cardTransfer } from '@/apis/order.recp.api'
// import { cardCertify } from '@/apis/order.pay.api'
import {
	postWebOrderInfoById,
	fetchWebOrderInfoById
} from '@/apis/order.status.api'
import { sendAlimTalk, fetchEContract } from '@/apis/order.ec.api'
import { registerCallbackToApp } from '@/utils/navigation'
export default {
	mixins: [filters],
	data() {
		return {
			recpInfos: {
				amount: '',
				kunnr: '',
				userId: '',
				phone: '',
				zwebRecp: '' //04:판매인 07:고객수납
			},
			mthd: '02', // 카드이체 - 기타결제
			status: '',
			webOrderSum: {},
			isPreContract: this.$store.state['verify'].customer.orderType === 'C'
		}
	},
	created() {
		this.initAppPage()
	},
	mounted() {
		this.recpInfos = this.$store.getters['recp/recpInfos']
		if (!this.recpInfos.mthd) this.recpInfos.mthd = this.mthd

		// 진행현황 재진입시
		const { status } = this.$route.query
		if (status) {
			this.status = status
		}
	},
	methods: {
		onResetPayMethod() {
			this.$router.go(-1)
		},
		async chkReturn(prm, rtn, tcObj) {
			// this.$log('rtn:', JSON.stringify(rtn))
			if (rtn.TYPE === 'S') {
				if (this.status === '106') {
					// 직접인증 가상계좌 서명완료 후 재진입
					await this.$alert({
						message: '수납이 완료됐습니다.<br />주문을 확정합니다.',
						okText: '주문확정'
					})
				} else {
					await this.$alert({
						message:
							'결제는 설치정보 작성 완료 후 진행됩니다.<br />설치 정보 화면으로 이동합니다.',
						okText: '설치정보 화면으로 이동'
					})
				}
				// store 저장
				this.$store.dispatch('recp/setRecpParams', { ...prm })

				// 웹 주문정보 저장
				const mobOrderNo = this.$store.getters['verify/mobOrderNo']

				const res = await fetchWebOrderInfoById(mobOrderNo)
				let webParams = res.resultObject
				let order = JSON.parse(webParams.orderInfoJson)
				this.webOrderSum = order.PAY_SUMMARY_AMT

				order = Object.assign(order, {
					recp: {
						recpParams: this.$store.getters['recp/recpParams'],
						recpInfos: this.$store.getters['recp/recpInfos'],
						recpCardResult: tcObj,
						isCustSelfPay: false,
						payRecp: this.$store.getters['recp/payRecp']
					}
				})
				if (this.status === '106') {
					webParams.step = '07' // 주문확정
					webParams.statusCode = this.isPreContract ? '112' : '109' // 주문확정 or 배정대기
				} else {
					webParams.step = '05' // 설치정보
					webParams.statusCode = '107' // 설치대기
				}
				webParams.orderInfoJson = JSON.stringify(order)

				const postRes = await postWebOrderInfoById(mobOrderNo, webParams)
				this.$log('postRes: ', JSON.stringify(postRes))
				if (this.status === '106') {
					// 진행현황
					// 알림톡 발송
					let ecParams = {
						mobOrderNo: this.$store.getters['verify/mobOrderNo']
					}
					const ecRes = await fetchEContract(ecParams)
					const orderList = ecRes.resultObject.data[0].setup
					let prdNm = orderList[0] ? orderList[0].GOODS_NM : '제품명'
					let planDt = orderList[0].FIRST_DT ?? ''
					let count =
						orderList && orderList.length > 1 ? orderList.length - 1 : ''
					let ordNoStr = ''
					orderList.forEach((res, i) => {
						if (i === 0) {
							ordNoStr = `${res.ORDER_NO}`
						} else {
							ordNoStr = `${ordNoStr}, ${res.ORDER_NO}`
						}
					})
					let sumAmt = ecRes.resultObject.data[0].sumAmt
					sumAmt.MONTH_AMT_DC = this.$store.getters['pay/monthAmtDc']
					sumAmt.MONTH_AMT_TOTAL = sumAmt.MONTH_AMT - sumAmt.MONTH_AMT_DC

					let atParams = {
						reqCd: this.isPreContract ? '6' : '2', // 1 : 계약서발송, 2 : 주문완료, 3 : 서명완료재진입
						gubun: this.custSelf ? '1' : '2', // 1 : 고객직접, 2 : 코디대행
						name: this.$store.getters['verify/custName'],
						phone: this.$store.getters['verify/custPhone'],
						orderNo: ordNoStr,
						url: `${process.env.VUE_APP_FRT_URL}/order/confirm`, // 주문확정 링크
						orderInfoId: this.$store.getters['verify/mobOrderNo'], // 웹주문번호
						customerNo: this.$store.getters['verify/custNo'],
						codyNo: this.$store.getters['common/codyNo'],
						codyPhoneNo: this.$store.getters['common/codyPhoneNo'],
						goodsNm: prdNm,
						planDt: planDt,
						goodsInfo: count !== '' ? `${prdNm} 외 ${count}대` : `${prdNm}`,
						onceAmt: this.$options.filters.comma(
							this.webOrderSum.totalSumOfRecpAmt
						),
						monthAmt: this.$options.filters.comma(
							this.webOrderSum.totalSumOfMonthAmt
						)
					}

					await sendAlimTalk(atParams)

					this.$router.push({ name: 'order-status' })
				} else {
					// 설치정보
					this.$router.push({ name: 'order-inst' })
				}
			} else {
				const options = {
					title: '다음과 같은 사유로 수납이 거부됐습니다.',
					guide: '다른 카드로 수납하시기 바랍니다.',
					contents: {
						거부사유: rtn.MESSAGE
					},
					okText: '수납방법 재선택',
					cancelText: ''
				}

				const popRes = await this.$refs.recpPop.open(options)

				if (popRes) {
					// 수납방법 재선택
					this.onResetPayMethod()
				}
			}
		},
		async goRecp() {
			const formData = this.$refs.card.onSubmit()
			if (!formData) return
			this.$log('card formData:', formData)

			let payRecp = {
				AF_OWNER_NM: formData.custName,
				AF_BANK_CD_NM: formData.bankNm,
				AF_CARD_NO: formData.accountNo
			}

			this.$store.dispatch('recp/setPayRecp', payRecp)

			const options = {
				title:
					'다음 카드를 통해 결제를 요청합니다.<br />결제는 설치정보 작성 완료 후 진행됩니다.',
				contents: {
					수납방법: `${formData.bankNm} 
					${this.$options.filters.star(formData.accountNo, 6)}`,
					수납금액: `${this.$options.filters.comma(this.recpInfos.amount)}원`
				},
				okText: '결제',
				cancelText: '수납방법 재선택'
			}

			const popRes = await this.$refs.recpPop.open(options)
			if (popRes) {
				let prm = {
					zwebRecp: '00',
					payMthd: this.mthd,
					userId: this.recpInfos.userId,
					cardn: formData.accountNo,
					avDat: `20${formData.avDatYear}${formData.avDatMonth}`,
					okamt: this.recpInfos.amount,
					kunnr: this.recpInfos.kunnr,
					name: formData.custName,
					depositor: formData.custName,
					restry: formData.custType,
					ssn: formData.ssn,
					bankc: formData.bankCd,
					bankNm: formData.bankNm,
					phone: this.$store.getters['verify/custPhone'],
					mobOrderNo: this.$store.state['verify'].mobOrderNo,
					insMon: formData.insMon
				}

				const res = await cardTransfer(prm)
				const rtn = res.resultObject.data.E_RETURN
				await this.chkReturn(prm, rtn, res.resultObject.data.TC_CARD[0])
				//카드인증
				// let param = {
				// 	reqId: this.recpInfos.userId, //코디번호
				// 	avDat: `20${formData.avDatYear}${formData.avDatMonth}`, //유효기간
				// 	cardn: formData.accountNo, // 카드번호
				// 	resId: formData.ssn, //생년월일
				// 	kunnr: this.recpInfos.kunnr,
				// 	transDt: '',
				// 	bankc: formData.bankCd,
				// 	bankNm: formData.bankNm,
				// 	name: formData.custName,
				// 	regerNo: this.recpInfos.kunnr,
				// 	custType: formData.custType,
				// 	gubun: 'Y' //정기결제 정보에 입력하지 않기 위해
				// }
				// let card = {
				// 	I_CARDN: formData.accountNo
				// }
				// const res = await cardCertify(param)
				// const rtn = res.resultObject.data
				// await this.chkReturn(prm, rtn, card)
			} else {
				// 수납방법 재선택
				this.onResetPayMethod()
			}
		},
		initAppPage() {
			registerCallbackToApp(
				{
					cbPhysicalBack: 'cbPhysicalBack'
				},
				this
			)
		},
		cbPhysicalBack() {
			this.onResetPayMethod()
		}
	}
}
</script>
